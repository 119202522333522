import type { RouterConfig } from '@nuxt/schema';

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: (_routes) => [
    ..._routes,
    {
      name: 'app_store_redirect',
      path: '/app.html',
      component: () => import('~/pages/index.vue'),
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
};
