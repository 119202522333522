export default defineNuxtPlugin((nuxtApp) => {
  if (process.server) {
    return;
  }

  useHead({
    script: [
      {
        children: `
        <!-- Google Tag Manager -->
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WSKQ2N35');
        <!-- End Google Tag Manager -->`,
      },
      {
        src: 'https://www.googletagservices.com/tag/js/gpt.js',
      },
      {
        children: `
      var googletag = googletag || {};
      googletag.cmd = googletag.cmd || [];

      googletag.cmd.push(function () {
        googletag.pubads().setTargeting('network', 'IE');
        googletag.pubads().setTargeting('Branding', 'elevate');
        googletag.pubads().enableSingleRequest(true);
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
      });`,
        'data-order': 6,
      },
    ],
  });
});
