import { default as _91slug_93GFkEPRBQ6RMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/[category]/for-sale/[slug].vue?macro=true";
import { default as indexysagvxBpuaMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/[category]/index.vue?macro=true";
import { default as account_45deletedVEdTBrt6XZMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/account-deleted.vue?macro=true";
import { default as account_45deletion_45failedX6kDaaUtOmMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/account-deletion-failed.vue?macro=true";
import { default as animal_45policyXRbpVSAHVbMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/animal-policy.vue?macro=true";
import { default as appzX4kIrWknvMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/app.vue?macro=true";
import { default as cookie_45policyRVxBCV41LhMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/cookie-policy.vue?macro=true";
import { default as indexwjmh0dajT6Meta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/index.vue?macro=true";
import { default as editmRgIl6inI7Meta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-adverts/[uuid]/edit.vue?macro=true";
import { default as previewcnoNB3vlYZMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-adverts/[uuid]/preview.vue?macro=true";
import { default as indexIKWvI9MDpfMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-adverts/index.vue?macro=true";
import { default as newwwireT2C50Meta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-adverts/new.vue?macro=true";
import { default as my_45favouritesddaHVcee2DMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-favourites.vue?macro=true";
import { default as _91uuid_931t6SYHabRCMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-messages/[uuid].vue?macro=true";
import { default as indexSLiFF29WGHMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-messages/index.vue?macro=true";
import { default as my_45profileSzOqQ4ebd4Meta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-profile.vue?macro=true";
import { default as payment_45historyla7uZmfxOuMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/payment-history.vue?macro=true";
import { default as saved_45searcheshO3Qx7ZlDOMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/saved-searches.vue?macro=true";
import { default as dashboardFw7fJ2qpJDMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard.vue?macro=true";
import { default as dealer_45enquiriesE8YlJ2YUErMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dealer-enquiries.vue?macro=true";
import { default as indexbI7s7EbKLYMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/for-sale/index.vue?macro=true";
import { default as how_45to_45sellU4GFhtWvjaMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/how-to-sell.vue?macro=true";
import { default as indexpqlPZ9Ctt7Meta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/index.vue?macro=true";
import { default as privacy_45policyxIYwSzFZDwMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/privacy-policy.vue?macro=true";
import { default as datas8yHPCgannMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/reset-password/data.vue?macro=true";
import { default as searchbHonLntBhLMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/search.vue?macro=true";
import { default as _91slug_93NXljvhmlt0Meta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/stockyard/[slug].vue?macro=true";
import { default as terms_45and_45conditionsc2TLgr8UhSMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/terms-and-conditions.vue?macro=true";
import { default as _91token_93LkqmPbJjvGMeta } from "/home/runner/work/haystack-nuxt/haystack-nuxt/pages/verify-email/[token].vue?macro=true";
export default [
  {
    name: _91slug_93GFkEPRBQ6RMeta?.name ?? "category-for-sale-slug",
    path: _91slug_93GFkEPRBQ6RMeta?.path ?? "/:category()/for-sale/:slug()",
    meta: _91slug_93GFkEPRBQ6RMeta || {},
    alias: _91slug_93GFkEPRBQ6RMeta?.alias || [],
    redirect: _91slug_93GFkEPRBQ6RMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/[category]/for-sale/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexysagvxBpuaMeta?.name ?? "category",
    path: indexysagvxBpuaMeta?.path ?? "/:category()",
    meta: indexysagvxBpuaMeta || {},
    alias: indexysagvxBpuaMeta?.alias || [],
    redirect: indexysagvxBpuaMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: account_45deletedVEdTBrt6XZMeta?.name ?? "account-deleted",
    path: account_45deletedVEdTBrt6XZMeta?.path ?? "/account-deleted",
    meta: account_45deletedVEdTBrt6XZMeta || {},
    alias: account_45deletedVEdTBrt6XZMeta?.alias || [],
    redirect: account_45deletedVEdTBrt6XZMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/account-deleted.vue").then(m => m.default || m)
  },
  {
    name: account_45deletion_45failedX6kDaaUtOmMeta?.name ?? "account-deletion-failed",
    path: account_45deletion_45failedX6kDaaUtOmMeta?.path ?? "/account-deletion-failed",
    meta: account_45deletion_45failedX6kDaaUtOmMeta || {},
    alias: account_45deletion_45failedX6kDaaUtOmMeta?.alias || [],
    redirect: account_45deletion_45failedX6kDaaUtOmMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/account-deletion-failed.vue").then(m => m.default || m)
  },
  {
    name: animal_45policyXRbpVSAHVbMeta?.name ?? "animal-policy",
    path: animal_45policyXRbpVSAHVbMeta?.path ?? "/animal-policy",
    meta: animal_45policyXRbpVSAHVbMeta || {},
    alias: animal_45policyXRbpVSAHVbMeta?.alias || [],
    redirect: animal_45policyXRbpVSAHVbMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/animal-policy.vue").then(m => m.default || m)
  },
  {
    name: appzX4kIrWknvMeta?.name ?? "app",
    path: appzX4kIrWknvMeta?.path ?? "/app",
    meta: appzX4kIrWknvMeta || {},
    alias: appzX4kIrWknvMeta?.alias || [],
    redirect: appzX4kIrWknvMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/app.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyRVxBCV41LhMeta?.name ?? "cookie-policy",
    path: cookie_45policyRVxBCV41LhMeta?.path ?? "/cookie-policy",
    meta: cookie_45policyRVxBCV41LhMeta || {},
    alias: cookie_45policyRVxBCV41LhMeta?.alias || [],
    redirect: cookie_45policyRVxBCV41LhMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: dashboardFw7fJ2qpJDMeta?.name ?? undefined,
    path: dashboardFw7fJ2qpJDMeta?.path ?? "/dashboard",
    meta: dashboardFw7fJ2qpJDMeta || {},
    alias: dashboardFw7fJ2qpJDMeta?.alias || [],
    redirect: dashboardFw7fJ2qpJDMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: indexwjmh0dajT6Meta?.name ?? "dashboard",
    path: indexwjmh0dajT6Meta?.path ?? "",
    meta: indexwjmh0dajT6Meta || {},
    alias: indexwjmh0dajT6Meta?.alias || [],
    redirect: indexwjmh0dajT6Meta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: editmRgIl6inI7Meta?.name ?? "dashboard-my-adverts-uuid-edit",
    path: editmRgIl6inI7Meta?.path ?? "my-adverts/:uuid()/edit",
    meta: editmRgIl6inI7Meta || {},
    alias: editmRgIl6inI7Meta?.alias || [],
    redirect: editmRgIl6inI7Meta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-adverts/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: previewcnoNB3vlYZMeta?.name ?? "dashboard-my-adverts-uuid-preview",
    path: previewcnoNB3vlYZMeta?.path ?? "my-adverts/:uuid()/preview",
    meta: previewcnoNB3vlYZMeta || {},
    alias: previewcnoNB3vlYZMeta?.alias || [],
    redirect: previewcnoNB3vlYZMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-adverts/[uuid]/preview.vue").then(m => m.default || m)
  },
  {
    name: indexIKWvI9MDpfMeta?.name ?? "dashboard-my-adverts",
    path: indexIKWvI9MDpfMeta?.path ?? "my-adverts",
    meta: indexIKWvI9MDpfMeta || {},
    alias: indexIKWvI9MDpfMeta?.alias || [],
    redirect: indexIKWvI9MDpfMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-adverts/index.vue").then(m => m.default || m)
  },
  {
    name: newwwireT2C50Meta?.name ?? "dashboard-my-adverts-new",
    path: newwwireT2C50Meta?.path ?? "my-adverts/new",
    meta: newwwireT2C50Meta || {},
    alias: newwwireT2C50Meta?.alias || [],
    redirect: newwwireT2C50Meta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-adverts/new.vue").then(m => m.default || m)
  },
  {
    name: my_45favouritesddaHVcee2DMeta?.name ?? "dashboard-my-favourites",
    path: my_45favouritesddaHVcee2DMeta?.path ?? "my-favourites",
    meta: my_45favouritesddaHVcee2DMeta || {},
    alias: my_45favouritesddaHVcee2DMeta?.alias || [],
    redirect: my_45favouritesddaHVcee2DMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-favourites.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_931t6SYHabRCMeta?.name ?? "dashboard-my-messages-uuid",
    path: _91uuid_931t6SYHabRCMeta?.path ?? "my-messages/:uuid()",
    meta: _91uuid_931t6SYHabRCMeta || {},
    alias: _91uuid_931t6SYHabRCMeta?.alias || [],
    redirect: _91uuid_931t6SYHabRCMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-messages/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexSLiFF29WGHMeta?.name ?? "dashboard-my-messages",
    path: indexSLiFF29WGHMeta?.path ?? "my-messages",
    meta: indexSLiFF29WGHMeta || {},
    alias: indexSLiFF29WGHMeta?.alias || [],
    redirect: indexSLiFF29WGHMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-messages/index.vue").then(m => m.default || m)
  },
  {
    name: my_45profileSzOqQ4ebd4Meta?.name ?? "dashboard-my-profile",
    path: my_45profileSzOqQ4ebd4Meta?.path ?? "my-profile",
    meta: my_45profileSzOqQ4ebd4Meta || {},
    alias: my_45profileSzOqQ4ebd4Meta?.alias || [],
    redirect: my_45profileSzOqQ4ebd4Meta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/my-profile.vue").then(m => m.default || m)
  },
  {
    name: payment_45historyla7uZmfxOuMeta?.name ?? "dashboard-payment-history",
    path: payment_45historyla7uZmfxOuMeta?.path ?? "payment-history",
    meta: payment_45historyla7uZmfxOuMeta || {},
    alias: payment_45historyla7uZmfxOuMeta?.alias || [],
    redirect: payment_45historyla7uZmfxOuMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/payment-history.vue").then(m => m.default || m)
  },
  {
    name: saved_45searcheshO3Qx7ZlDOMeta?.name ?? "dashboard-saved-searches",
    path: saved_45searcheshO3Qx7ZlDOMeta?.path ?? "saved-searches",
    meta: saved_45searcheshO3Qx7ZlDOMeta || {},
    alias: saved_45searcheshO3Qx7ZlDOMeta?.alias || [],
    redirect: saved_45searcheshO3Qx7ZlDOMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dashboard/saved-searches.vue").then(m => m.default || m)
  }
]
  },
  {
    name: dealer_45enquiriesE8YlJ2YUErMeta?.name ?? "dealer-enquiries",
    path: dealer_45enquiriesE8YlJ2YUErMeta?.path ?? "/dealer-enquiries",
    meta: dealer_45enquiriesE8YlJ2YUErMeta || {},
    alias: dealer_45enquiriesE8YlJ2YUErMeta?.alias || [],
    redirect: dealer_45enquiriesE8YlJ2YUErMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/dealer-enquiries.vue").then(m => m.default || m)
  },
  {
    name: indexbI7s7EbKLYMeta?.name ?? "for-sale",
    path: indexbI7s7EbKLYMeta?.path ?? "/for-sale",
    meta: indexbI7s7EbKLYMeta || {},
    alias: indexbI7s7EbKLYMeta?.alias || [],
    redirect: indexbI7s7EbKLYMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/for-sale/index.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45sellU4GFhtWvjaMeta?.name ?? "how-to-sell",
    path: how_45to_45sellU4GFhtWvjaMeta?.path ?? "/how-to-sell",
    meta: how_45to_45sellU4GFhtWvjaMeta || {},
    alias: how_45to_45sellU4GFhtWvjaMeta?.alias || [],
    redirect: how_45to_45sellU4GFhtWvjaMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/how-to-sell.vue").then(m => m.default || m)
  },
  {
    name: indexpqlPZ9Ctt7Meta?.name ?? "index",
    path: indexpqlPZ9Ctt7Meta?.path ?? "/",
    meta: indexpqlPZ9Ctt7Meta || {},
    alias: indexpqlPZ9Ctt7Meta?.alias || [],
    redirect: indexpqlPZ9Ctt7Meta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyxIYwSzFZDwMeta?.name ?? "privacy-policy",
    path: privacy_45policyxIYwSzFZDwMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyxIYwSzFZDwMeta || {},
    alias: privacy_45policyxIYwSzFZDwMeta?.alias || [],
    redirect: privacy_45policyxIYwSzFZDwMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: datas8yHPCgannMeta?.name ?? "reset-password-data",
    path: datas8yHPCgannMeta?.path ?? "/reset-password/data",
    meta: datas8yHPCgannMeta || {},
    alias: datas8yHPCgannMeta?.alias || [],
    redirect: datas8yHPCgannMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/reset-password/data.vue").then(m => m.default || m)
  },
  {
    name: searchbHonLntBhLMeta?.name ?? "search",
    path: searchbHonLntBhLMeta?.path ?? "/search",
    meta: searchbHonLntBhLMeta || {},
    alias: searchbHonLntBhLMeta?.alias || [],
    redirect: searchbHonLntBhLMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NXljvhmlt0Meta?.name ?? "stockyard-slug",
    path: _91slug_93NXljvhmlt0Meta?.path ?? "/stockyard/:slug()",
    meta: _91slug_93NXljvhmlt0Meta || {},
    alias: _91slug_93NXljvhmlt0Meta?.alias || [],
    redirect: _91slug_93NXljvhmlt0Meta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/stockyard/[slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsc2TLgr8UhSMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsc2TLgr8UhSMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsc2TLgr8UhSMeta || {},
    alias: terms_45and_45conditionsc2TLgr8UhSMeta?.alias || [],
    redirect: terms_45and_45conditionsc2TLgr8UhSMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91token_93LkqmPbJjvGMeta?.name ?? "verify-email-token",
    path: _91token_93LkqmPbJjvGMeta?.path ?? "/verify-email/:token()",
    meta: _91token_93LkqmPbJjvGMeta || {},
    alias: _91token_93LkqmPbJjvGMeta?.alias || [],
    redirect: _91token_93LkqmPbJjvGMeta?.redirect,
    component: () => import("/home/runner/work/haystack-nuxt/haystack-nuxt/pages/verify-email/[token].vue").then(m => m.default || m)
  }
]